const JobsData = [
  {
    title: "full stack web developer",
    hours: "Full Time",
    slug: "full-stack-web-developer",
    detail: [
      {
        title: "Job Description",
        info: "WebSailors is a web design and development company headquartered in Mississauga, Canada. For the role of PHP Full Stack Developer, we are seeking to employ qualified individuals. A tech enthusiast that would fit in well with our team, has a love for innovation, and has the creative vision to turn a simple wireframe into a compelling digital user experience",
        list: [
          "Constructing the front-end architecture of a website.",
          "Creating user interfaces for websites.",
          "Creating apps for websites' back ends.",
          "Developing databases and servers to make things work.",
          "Making sure mobile phones are cross-platform optimized.",
          "Ensuring that apps are responsive.",
          "Collaborating with graphic designers to develop functionality for websites.",
          "Creating tested, effective, and adaptable code.",
          "Design and execution of applications with low latency, high availability, and performance.",
          "implementation of data protection and security.",
          "Developing front-end application API endpoints that are secure.",
          "Database operations and queries.",
          "Creating and constructing APIs",
          "Possess excellent self-management skills, a can-do attitude, and understanding of workflow and version control systems.",
          "Keeping up with advancements in programming languages and web apps",
          "Excellent communication and verbal skills",
          "Excellent problem-solving abilities and meticulousness",
        ],
      },
      {
        title: "Skills Required",
        list: [
          "BS in Computer Science and Engineering (or related technical discipline)",
          "2-4 years of.Net core and React experience",
          "2+ Years of MS SQL Server experience",
          "good familiarity with reporting tools.",
          "good command of ORM and ADO.Net",
          "a solid understanding of agile methodology.",
          "Knowledge of SignalR, gRPC, or socket programming will be viewed favorably.",
        ],
      },
      {
        title: "Job with Perks",
        list: [
          "Competitive Salary",
          "Health Insurance",
          "Paid Leaves",
          "Annual Dinner",
          "Performance Bonuses",
          "Excursion Trip",
          "Lunch",
        ],
      },
    ],
  },
  {
    title: "Mern Stack developer.",
    hours: "Full Time",
    slug: "mern-stack-web-developer",
    detail: [
      {
        title: "Job Description",
        info: "We are searching for an experienced mern stack developer to design and manage our whole web infrastructure. Candidates who are chosen for the position must have a minimum of two years of recent experience with each of the abilities indicated below. In order to complete our project, we need to build our API, connect it to our pre-approved user interface, and set up our server architecture. This covers all related fields, including database design and architecture design.",
        list: [
          "Must be extremely knowledgeable with React JS, React Native, Node JS, Express JS, Redux, Restful APIs, JavaScript, jQuery, HTML5, CSS3, and MongoDB.",
          "Create and manage online and mobile apps using React Native, React JS, and Node.js.",
          "strong database and object-oriented programming ideas.",
          "Make judgments on the code architecture to provide a high-performance, scalable product with a small footprint.",
          "Correct and enhance any technological problems.",
          "Both relational and non-relational databases should be designed and maintained to be huge and complicated.",
          "Using innovative web technologies and platforms, agile web development.",
          "Develop high-quality code with thorough unit test coverage.",
          "Responsive designs may be made by binding UI components to JavaScript object models.",
          "Be receptive to requests for features and changes.",
          "A track record of creating websites from scratch that are pixel-perfect starting from a design concept.",
        ],
      },
      {
        title: "Skills Required",
        list: [
          "Proven working knowledge of Node JS and web programming Knowledge of Vue JS is a plus",
          "Excellent programming abilities and thorough understanding of contemporary HTML/CSS",
          "knowledge of at least one of the Web Stacks listed below: Mean and Mend",
          "strong familiarity with the inner workings of online applications, especially in terms of security, session management, and best development practices",
          "enough familiarity with web application development, relational database systems, and Object-Oriented Programming",
          "practical knowledge of JavaScript/jQuery, AJAX, etc.",
          "aggressive problem-solving and problem-diagnosis techniques",
          "Strong organizational abilities to manage many activities within time and financial restrictions as well as business acumen",
          "Ability to learn quickly and grasp a variety of web technologies and methodologies, as well as operate and flourish in a fast-paced workplace.",
          "Strong MySQL proficiency is necessary; experience with Firebase and NoSQL will be advantageous.",
        ],
      },
      {
        title: "Job with Perks",
        list: [
          "Competitive Salary",
          "Health Insurance",
          "Paid Leaves",
          "Annual Dinner",
          "Performance Bonuses",
          "Excursion Trip",
          "Lunch",
        ],
      },
    ],
  },
  {
    title: "Front End web developer",
    hours: "Full Time",
    slug: "front-end-web-developer",
    detail: [
      {
        title: "Job Description",
        info: "A Front-End Developer is needed by WebSailors, a US-based provider of web web design, development and product development services. The developer will be in charge of converting graphics files into interactive web pages using HTML, and animating user interfaces using CSS and JavaScript. The business combines innovative ideas with cutting-edge tactics to deliver experiences that can accelerate transformation. A long-term commitment may be possible for this full-time position. It requires a time zone difference with Eastern.",
        list: [
          "Creating commercial iOS and Android applications.",
          "Utilizing React Native to generate new user-facing features.",
          "Pixel-perfect conversion of drawings and wireframes into high-quality code.",
          "Use transitions and animations that are clear, contemporary, and give a great user experience.",
          "In Firestore, create and implement data models.",
          "Expertise integrating payment gateways is required (PP, Stripe etc.)",
          "Experience publishing apps to the Apple Store and Google Play Store is required.",
          "Experience publishing apps to the Apple Store and Google Play Store is required.",
          "Must have previous experience with Google Maps and geotracking",
          "Host and maintain the backend of an application using Firebase",
          "Create and implement Firebase Cloud features (NodeJS)",
          "Enhance the code and asset delivery process (CI / CD Flow)",
          "Create and put into practice an architecture and file system",
          "Create technical requirements, documentation, and development plans.",
        ],
      },
      {
        title: "Skills Required",
        list: [
          "Angular, Vue, React, and Node.js experience of at least one year is required.",
          "JavaScript expert with a solid grasp of the language's basics.",
          "web development expertise across the board.",
          "knowledge of responsive desktop/mobile design.",
          "extensive coding knowledge in HTML, CSS, AJAX, and Javascript (jQuery, Backbone, Angular, and Ember).",
          "CSS compiler expertise (E.g., Sass, LESS).",
          "knowledge of other JavaScript libraries and frameworks, such as ExtJS, Backbone.js, Angular.js, and Node.js.",
          "Working in an agile environment skills.",
        ],
      },
      {
        title: "Job with Perks",
        list: [
          "Competitive Salary",
          "Health Insurance",
          "Paid Leaves",
          "Annual Dinner",
          "Performance Bonuses",
          "Excursion Trip",
          "Lunch",
        ],
      },
    ],
  },
  {
    title: "Nodejs web developer",
    hours: "Part Time",
    slug: "node-js-web-developer",
    detail: [
      {
        title: "Job Description",
        info: "We are searching for an experienced Node.js developer to help us improve the efficiency of our web-based application. Designing back-end components, integrating data storage and protection technologies, and working with our front-end application developers will be the responsibilities. You should have in-depth understanding of Node.Js based services and prior experience in a comparable capacity to ensure success as a Node.js developer. A skilled Node.js developer will be able to send data between servers and end users in a way that is reliable, quick, and safe.",
        list: [
          "Strong JavaScript skills and acquaintance with at least ES6 syntax.",
          "Having knowledge of NoSQL and SQL databases, such as MongoDB, MySQL, etc.",
          "Have knowledge of mongoose ORM.",
          "Knowledge of REST principles and APIs.",
          "Understanding of contemporary authorisation concepts like JWT and OAuth.",
          "Knowledge of creating test cases",
          "Knowledge of maintaining and managing servers.",
          "Knowledge of socket.io",
          "Knowledge of code-versioning systems like Git, SVN, and Mercurial",
          "Knowledge with Docker and Kubernetes is advantageous.",
        ],
      },
      {
        title: "Skills Required",
        list: [
          "Extensive expertise of building Rest APIs, ExpressJS, MongoDB, Puppeteer, Regex, unit testing, Git, and AWS with 3+ years of experience developing NodeJS products (will be a huge plus).",
          "Strong knowledge of JavaScript",
          "simple familiarity with front-end technologies like HTML5 and CSS3",
          "Recognizing the basic design tenets of a scalable application",
          "English proficiency, both written and spoken",
        ],
      },
      {
        title: "Job with Perks",
        list: [
          "Competitive Salary",
          "Health Insurance",
          "Paid Leaves",
          "Annual Dinner",
          "Performance Bonuses",
          "Excursion Trip",
          "Lunch",
        ],
      },
    ],
  },
];

export default JobsData;
