import styles from "./Testimonials.module.css";
import Slider from "react-slick";

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };

  return (
    <div className={styles.TestimonialsWrapper}>
      <div className={`container`}>
        <Slider {...settings} className="test-slider">
          <div className={styles.testimonial}>
            <p className={styles.text}>
              “We’ve been working together hand in hand ever since they first
              started out, I don’t see myself moving to another service provider
              anytime soon or even in the future.”
            </p>
            <h4>
              Bilal Azhar - <span>BumpUp</span>
            </h4>
          </div>
          <div className={styles.testimonial}>
            <p className={styles.text}>
              “It’s been 2 years and we’re still going strong! It’s been great
              working with the Websailors INC. and I’ve never suffered from Scope
              Creeps whenever working with their team”
            </p>
            <h4>
              Usama Khursheed - <span>Gubloo Technologies</span>
            </h4>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default Testimonials;
