const WebData = [
  {
    id:5,
    title: "Graphics Design",
    description:
      "When it comes to Graphics design, it’s not just about having all the bells and whistles and looking good, we come up with designs that are Realistic and Objective. We mainly work in Adobe Illustrator, XD and the grandfather of all design work Adobe PS.",
  },
  {
    id:1,
    title: "JS",
    description:
      "We have a team of expert JavaScript developers that have expertise in creating custom front-end solutions from scratch.",
  },
  {
    id:1,
    title: "React / React-native",
    description:
      "One of today’s leading Front-end frameworks of today. It is a framework that we specialize in and have completed many Web / Mobile applications with.",
  },
  {
    id:1,
    title: "Angular",
    description:
      "A front-end framework that is constantly competing for the number 1 spot with modern front-end JS frameworks.",
  },
  {
    id:1,
    title: "Vue",
    description:
      "Although Vue is a relatively young and evolving framework we have completed and are currently developing projects using the technology, and we see a huge opportunity for growth in this Platform.",
  },
  {
    id:2,
    title: "PHP",
    description:
      "A general Purpose coding language that drives the world's most leading CMS and some great Libraries .",
  },
  {
    id:2,
    title: "Node",
    description:
      "Node.js is a backend JS based runtime environment running platform that is perfectly paired with React and Angular. Since most of our work force consists of MEAN and MERN stack developers, we have some of the best Node talents in our team.",
  },
  {
    id:2,
    title: "Laravel",
    description:
      "Laravel is an open source PHP web development framework. We've been in the Laravel market providing services all over US and Canada ever since we first set foot into the agency world.",
  },
  {
    id:1,
    title: "WordPress",
    description:
      "WordPress is the leading content management system of today. WordPress has started Booming all over the internet with a wide variety of custom and pre-planned solutions right out of the box.",
  },
  {
    id:1,
    title: "Shopify",
    description:
      "If you see a great online store, with all the knick knacks, there’s a great chance that it is built in Shopify. It is what everyone is using to create a great online store and we have some great Shopify talent in our team.",
  },
  {
    id:1,
    title: "SEO",
    description:
      "We are here to use our SEO expertise to rank your websites higher in search results. Our excellent use of tools like SEMrush, Ahrefs, and Google Analytics allows us to analyze competitors and the market to meet your goals. We conduct thorough keyword research, content optimization, and link building to maximize targeted audience engagement.",
  },
  {
    id:1,
    title: "Marketing",
    description:
      "We deliver powerful marketing solutions to make your brand stand out and engage your audience. Our customized strategies are built on data-driven insights and creative excellence. Let us help you achieve long-term business growth.",
  },
];

export default WebData;
