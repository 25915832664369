import React from "react";
import styles from "./OfficeLocation.module.css";
import { FiMapPin, FiPhone, FiMail } from "react-icons/fi";
import { emailPrimary, phonePK, phoneUS } from "../../../PhoneNumber";
const OfficeLoc = () => {
  const PhoneLink = ({ children }) => {
    return (
      <a href={`tel:${children}`}>
        <p className={styles.phone}>
          <span className={styles.icon}>
            <FiPhone />
          </span>
          <span className={styles.cityAdress}>{children}</span>
        </p>
      </a>
    );
  };

  const EmailLink = ({ children }) => {
    return (
      <a href={`mailto:${children}`} className={styles.email}>
        <span className={styles.icon}>
          <FiMail />
        </span>
        {children}
      </a>
    );
  };

  return (
    <div className={styles.officeLoc}>
      <div className="container">
        <div className={styles.OfficeLocInner}>
          <div className={styles.top}>
            <h3>Office Locations</h3>
          </div>
          <div className="row">
            <div className={`col-md-4 ${styles.location}`}>
              <div className={styles.locationInner}>
                <div className={styles.locationContent}>
                  <h4 className={styles.country}>Canada</h4>
                  <a
                    href="https://www.google.com/maps/place/5800+Ambler+Dr+Suite+119,+Mississauga,+ON+L4W+4J4,+Canada/@43.6429224,-79.6460878,17z/data=!3m1!4b1!4m5!3m4!1s0x882b38b5b5bfffff:0xac6142731de787a6!8m2!3d43.6429224!4d-79.6460878"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p className={styles.city}>
                      <span className={styles.icon}>
                        <FiMapPin />
                      </span>
                      <span className={styles.detailBox}>
                        5800 Ambler Drive,
                        <span className={styles.cityAdress}>
                          Unit # 119, Mississauga,
                        </span>
                        <span className={styles.cityAdress}>ON, L4W-4J4</span>
                      </span>
                    </p>
                  </a>
                  <PhoneLink>{phoneUS}</PhoneLink>
                  <EmailLink>{emailPrimary}</EmailLink>
                </div>
              </div>
            </div>
            <div className={`col-md-4 ${styles.location}`}>
              <div className={styles.locationInner}>
                <div className={styles.locationContent}>
                  <h4 className={styles.country}>USA</h4>
                  <a
                    href="https://www.google.com/maps/place/1480+Renaissance+Dr,+Park+Ridge,+IL+60068,+USA/@42.0335638,-87.8644125,17z/data=!3m1!4b1!4m5!3m4!1s0x880fb7db563caae5:0x7a6796be19585fd4!8m2!3d42.0335638!4d-87.8644125"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p className={styles.city}>
                      <span className={styles.icon}>
                        <FiMapPin />
                      </span>
                      <span className={styles.detailBox}>
                        1480 Renaissance
                        <span className={styles.cityAdress}>
                          Dr Park Ridge,
                        </span>
                        <span className={styles.cityAdress}>IL 60068</span>
                      </span>
                    </p>
                  </a>
                  <PhoneLink>{phoneUS}</PhoneLink>
                  <EmailLink>{emailPrimary}</EmailLink>
                </div>
              </div>
            </div>
            <div className={`col-md-4 ${styles.location}`}>
              <div className={styles.locationInner}>
                <div className={styles.locationContent}>
                  <h4 className={styles.country}>Pakistan</h4>
                  <a
                    href="https://www.google.com/maps/place/Taw-Seen+Complex/@33.5516168,73.1244362,17z/data=!4m12!1m6!3m5!1s0x0:0x5edcb32581000fbc!2sTaw-Seen+Complex!8m2!3d33.5514376!4d73.124285!3m4!1s0x0:0x5edcb32581000fbc!8m2!3d33.5514376!4d73.124285"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p className={styles.city}>
                      <span className={styles.icon}>
                        <FiMapPin />
                      </span>
                      <span className={styles.detailBox}>
                        Tawseen Complex, Building 75,
                        <span className={styles.cityAdress}>
                          Civic Center Phase 4, Bahria Town
                        </span>
                        <span className={styles.cityAdress}>
                          Islamabad, Pakistan.
                        </span>
                      </span>
                    </p>
                  </a>
                  <PhoneLink>{phonePK}</PhoneLink>
                  <EmailLink>{emailPrimary}</EmailLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfficeLoc;
