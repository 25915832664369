const Menu = [
  {
    title: "Who we are",
    slug: "/who-we-are",
  },

  {
    title: "Work",
    slug: "/work",
  },
  {
    title: "Technologies",
    slug: "/technologies",
  },
  // {
  //   title: "Team",
  //   slug: "/team",
  // },
  {
    title: "Blog",
    slug: "/blogs",
  },
  
  // {
  //   title: "Careers",
  //   slug: "/careers",
  // },
  {
    title: "Contact",
    slug: "/contact-us",
  },
];

export default Menu;
