import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import styles from "./WhatWeDo.module.css";
import { Link } from "react-router-dom";

const WhatWeDo = () => {
  useEffect(() => {
    AOS.init({ disable: "mobile" });
  }, []);
  return (
    <div className={styles.whatWrapper}>
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-lg-6 order-1 order-lg-0">
            <div className={styles.whatImages}>
              <img
                src="assets/images/what1.png"
                className={`d-none d-lg-block ${styles.whatOne}`}
                data-aos="fade-down"
                data-aos-delay="400"
                data-aos-duration="400"
                data-aos-easing="linear"
                alt="what we do "
              />
              <img
                src="assets/images/what2.png"
                className={`d-none d-lg-block ${styles.whatTwo}`}
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="700"
                data-aos-easing="linear"
                alt="what we do "
              />
              <img
                src="assets/images/what3.png"
                className={`d-none d-lg-block ${styles.whatThree}`}
                data-aos="fade-left"
                data-aos-delay="400"
                data-aos-duration="700"
                data-aos-easing="linear"
                alt="what we do"
              />
              <img
                src="assets/images/what.png"
                className={`d-block d-lg-none ${styles.whatOne}`}
                alt="what we do "
                height="auto"
                width="100%"
              />
            </div>
          </div>
          <div
            className={`col-xl-7 col-lg-6  pl-lg-5 order-0 order-lg-1 ${styles.whatContent}`}
            data-aos="fade-right"
            data-aos-delay="400"
            data-aos-duration="600"
            data-aos-easing="linear"
          >
            <h2>what we do!</h2>
            <p>
              Websailors INC. has been providing impactful and engaging
              web-based products to major companies across a wide range of
              industries. As a top web development company, we offer experienced
              experts, designers, and website development to help you take your
              web presence to the highest level. Alongside creating powerful
              secure, scalable web-based applications. Websailors INC. is also
              able to aid organizations in upgrading, migration, and
              integration. Additionally, we provide an all-inclusive Managed
              Services offering that includes 24-hour support and maintenance,
              which can handle the most complex and difficult cases quickly and
              effectively.{" "}
            </p>
            <p>
              We develop apps that are <b>Highly Functional & Easy To Use.</b>
            </p>
            <div className={`d-flex flex-column ${styles.whatOptions}`}>
              <span>Strategizing and Evaluation</span>
              <span>Web/App Design and Development</span>
              <span>SEO and Marketing</span>
              <span>Support and Management</span>
            </div>
            <Link to="/who-we-are" className="btn btn-primary">
              More About Us
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
