import React from "react";
import { Link } from "react-router-dom";
import styles from "./ViewWork.module.css";

const ViewWork = () => {
  return (
    <div className={styles.ViewWork}>
      <div className="container">
        <div className={styles.ViewWorkInner}>
          <h2>SEE WHAT WE'VE BEEN UP TO LATELY.</h2>
          <Link to="/work" className={`  ${styles.viewWork}`}>
            <button className={styles.viewWorkbtn}>View our work </button>
          </Link>
          <div className={styles.lookingFor}>
            <p className="text-center">
              Looking for a career making a difference in the world?
            </p>
          </div>

          <Link to="/careers" className={styles.getHired}>
            get hired
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ViewWork;
